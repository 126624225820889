(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/pix-handler/common/constants.js') >= 0) return;  svs.modules.push('/components/sport/pix-handler/common/constants.js');
'use strict';

const LOGGER_NAME = 'sport:pix-handler';
const ONE_MINUTE = 60 * 1000;
const PIX_CACHE_TIME = 10 * ONE_MINUTE;
const STORAGE_DOMAIN_KEY = 'quickplay2-react';
const KEEP_OPEN_CACHE_KEY = 'keepOpenItem';
const SYSTEM_TYPES = {
  SROW: 'SROW',
  MSYS: 'MSYS',
  USYS: 'USYS',
  UROW: 'UROW',
  RSYS: 'RSYS'
};
const FLAVOUR_TYPES = {
  SAFE_1X2: 'Safe1X2',
  SAFE_MALTIPSET: 'SafeMaltipset',
  SAFE_BOMBEN_MATCHEN: 'SafeBombenMatchen'
};
const QUICKPICK_PROPS = {
  WEIGHTEDQP: 'WEIGHTEDQP',
  MODIFIEDQP: 'MODIFIEDQP',
  NOQP: 'no-qp',
  PARTIALQP: 'PARTIALQP',
  XPERTEN: 'XPERTEN'
};
const ADDITIONAL_CACHE_KEYS = {
  PIXMIX: 'PIXMIX'
};
setGlobal('svs.components.sport.pixHandler.common.constants', {
  LOGGER_NAME,
  PIX_CACHE_TIME,
  SYSTEM_TYPES,
  FLAVOUR_TYPES,
  STORAGE_DOMAIN_KEY,
  QUICKPICK_PROPS,
  ADDITIONAL_CACHE_KEYS,
  KEEP_OPEN_CACHE_KEY
});

 })(window);